import { css } from '@emotion/react'
import { Link } from 'gatsby'
import React from 'react'
import useScrollDirection from '../hooks/useScrollDirection'

const headerCss = {
  self: {
    up: css({
      position: 'sticky',
      zIndex: 2,
      top: 0,
      borderBottom: '2px solid var(--charcoal)',
    }),
    down: css({
      display: 'none',
    }),
  },
  ul: css({
    display: 'flex',
    listStyle: 'none',
    padding: '8px 16px',
    margin: 0,
    li: {
      padding: '0 8px',
      ':not(:last-child)': {
        borderRight: '2px solid var(--charcoal)',
      },
    },
  }),
}

export default function Header() {
  const scrollDirection = useScrollDirection()

  return (
    <header
      css={scrollDirection === 'down' ? headerCss.self.down : headerCss.self.up}
    >
      <nav>
        <ul css={headerCss.ul}>
          <li>
            <Link to="/">Головна</Link>
          </li>
          <li>
            <Link to="/about/">Про мене</Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

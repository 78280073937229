exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-dentistry-journey-mdx": () => import("./../../../src/pages/blog/dentistry-journey.mdx" /* webpackChunkName: "component---src-pages-blog-dentistry-journey-mdx" */),
  "component---src-pages-blog-watches-mdx": () => import("./../../../src/pages/blog/watches.mdx" /* webpackChunkName: "component---src-pages-blog-watches-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-post-tsx-content-file-path-src-pages-blog-dentistry-journey-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/pages/blog/dentistry-journey.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-pages-blog-dentistry-journey-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-pages-blog-watches-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/pages/blog/watches.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-pages-blog-watches-mdx" */)
}

